import React, { useEffect, useState } from "react";
import { Logo } from "../../resources/Logos";
import UserServices from "../../services/UserServices";
import { Link, useLocation } from "react-router-dom";
import TagManager from "react-gtm-module";
import Loading from "../../components/Loading";
import { withLanguage } from "../../providers/LanguageContext";
import {withContext} from "../../providers/UserContext";
import Topbar from "../../components/Topbar";
import {Col, Row} from "react-bootstrap";

const StripeScreen = (props: any) => {
  const { currentLanguage, user } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [subscribed, setSubscribed] = useState(false);
  const [stripeData, setStripeData] = useState(null);

  //Get params from url
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const success = queryParams.get("success");
  const session_id = queryParams.get("session_id");

  const updateUser = async () => {
    if(success === "true") {
      setIsLoading(true);
      try {
        const userUpdated = await UserServices.editUser (
            {
              userId: user.data.id,
              stripe_session_id: session_id!,
            },
            user.auth.token
        );
        setStripeData(userUpdated.data.stripe);
        setIsLoading(false);
        setSubscribed(true);
      } catch (e) {
        setIsLoading(false);
        console.error(e);
      }
      if(stripeData) {
        TagManager.dataLayer({
          dataLayer: {
            event: "purchase",
            transaction_id: session_id,
            value: stripeData.amount_total / 100,
            currency: stripeData.currency
          }
        });
      }
      setIsLoading(false);
      setSubscribed(true);
    } else {
      setIsLoading(false);
      setSubscribed(false);
    }
  };

  useEffect(() => { updateUser(); }, []);

  function renderSubscribed() {
    return (
      <>
        <Row className="text-1">
          <Col>
            <span>
            {currentLanguage && currentLanguage === "es"
                ? "Tu Subscripcion ya esta activa!"
                : "Your Subscription has been activated!"}
            </span>
          </Col>
        </Row>
        <br/>
        <Row className="text-2">
          <Col>
            <button className="primary-background-color text-white font-bold py-2 px-6 mb-4">
              <Link to="/" className="text-white no-underline">
                {currentLanguage && currentLanguage === "es"
                    ? "Empezar a ver!"
                    : "Start Watching!"}
              </Link>
            </button>
          </Col>
        </Row>
      </>
    )
  }

  function renderNotSubscribed() {
    return (
        <>
          <Row className="text-1">
            <Col>
              <span className="text-white mb-4 text-2xl">
                {currentLanguage && currentLanguage === "es"
                    ? "Hubo un problema con tu subscripción, por favor intenta nuevamente."
                    : "There was a problem with your subscription, please try again."}
              </span>
            </Col>
          </Row>
          <Row className="text-2">
            <Col>
              <Link to="/" className="text-white no-underline">
                {currentLanguage && currentLanguage === "es"
                    ? "Inicio"
                    : "Go home"}
              </Link>
            </Col>
          </Row>
        </>
    )
  }

  function renderIsLoading() {
    return (
      <Row className="text-1">
        <Col>
          <Loading show />
        </Col>
      </Row>
    )
  }

  function Content() {
    if(isLoading) {
      return renderIsLoading();
    } else if (subscribed) {
      return renderSubscribed();
    } else {
      return renderNotSubscribed();
    }
  }

  return (
    <div className="container-stripe">
      <Topbar />
      <div className="flex justify-center items-center flex-col m-5">
        <Content />
      </div>
    </div>
  )
};

export default withLanguage(withContext(StripeScreen));